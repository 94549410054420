<template>

  <div class="container py-3">
    <a-card :bordered="false" class="ant-pro-components-tag-select">
      <a-form layout="inline">
        <a-input-search placeholder="请输入区域或地址开始查询资产" style="width: 50%" v-model="address" @search="onSearch"
          :allowClear="true" />
        <standard-form-row title="房源位置" block style="padding-bottom: 11px;padding-top: 20px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="district">
              <tag-select-option value="11">上海市区</tag-select-option>
              <tag-select-option value="22">金山区</tag-select-option>
            </tag-select>
          </a-form-item>
          <a-form-item v-show="districtList.includes('22')">
            <tag-select v-model="town">
              <tag-select-option value="1">朱泾镇</tag-select-option>
              <tag-select-option value="2">枫泾镇</tag-select-option>
              <tag-select-option value="3">张堰镇</tag-select-option>
              <tag-select-option value="4">亭林镇</tag-select-option>
              <tag-select-option value="5">吕巷镇</tag-select-option>
              <tag-select-option value="6">廊下镇</tag-select-option>
              <tag-select-option value="7">金山卫镇</tag-select-option>
              <tag-select-option value="8">漕泾镇</tag-select-option>
              <tag-select-option value="9">山阳镇</tag-select-option>
              <tag-select-option value="10">石化街道</tag-select-option>
            </tag-select>

          </a-form-item>
        </standard-form-row>

        <standard-form-row title="房屋类型" block style="padding-bottom: 11px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="houseType">
              <tag-select-option value="1">商铺</tag-select-option>
              <tag-select-option value="2">写字楼</tag-select-option>
              <tag-select-option value="3">综合体</tag-select-option>
              <tag-select-option value="4">厂房</tag-select-option>
              <tag-select-option value="5">其他商业</tag-select-option>
              <tag-select-option value="6">住宅</tag-select-option>
            </tag-select>
          </a-form-item>
        </standard-form-row>

        <standard-form-row title="房屋面积" block style="padding-bottom: 11px;">
          <a-form-item style="margin-top: -4px;">
            <tag-select v-model="floorSpace">
              <tag-select-option value="1">＜100㎡</tag-select-option>
              <tag-select-option value="2">100-300㎡</tag-select-option>
              <tag-select-option value="3">300-500㎡</tag-select-option>
              <tag-select-option value="4">500-1000㎡</tag-select-option>
              <tag-select-option value="5">＞1000㎡</tag-select-option>
            </tag-select>
          </a-form-item>
        </standard-form-row>
      </a-form>
    </a-card>
    <div class="map">
      <div id="container" class="map">
      </div>
    </div>
  </div>




</template>

<script>
import HouseItem from '@/components/property/HouseItem'
import StandardFormRow from '@/components/StandardFormRow'
import TagSelect from '@/components/TagSelect'
import { listMapAsset } from "@/request/api";
import AMapLoader from '@amap/amap-jsapi-loader';
const TagSelectOption = TagSelect.Option

export default {
  components: {
    TagSelect,
    TagSelectOption,
    StandardFormRow,
    HouseItem
  },
  data() {
    return {
      district: {}, //房源位置
      districtList: [],
      town: {}, // 金山区内房源位置（子数据）
      townList: [],
      houseType: {}, //房屋类型
      houseTypeList: [],
      floorSpace: {}, //房屋面积
      floorSpaceList: [],
      address: "",
      houseEntity: [],
      //地图点聚合
      map: null,
      markerList: [],
      clusterData: null,
    }
  },
  mounted() {
    this.initMap();
  },
  watch: {
    district(newVal) {
      this.districtList = this.screen(newVal, this.districtList, ["11", "22"])
      this.markerClusterer()
    },
    town(newVal) {
      this.townList = this.screen(newVal, this.townList, ["1","2", "3", "4", "5", "6", "7", "8", "9", "10"])
      this.markerClusterer()
    },
    houseType(newVal) {
      this.houseTypeList = this.screen(newVal, this.houseTypeList, ["1", "2", "3", "4", "5", "6"])
      this.markerClusterer()
    },
    floorSpace(newVal) {
      this.floorSpaceList = this.screen(newVal, this.floorSpaceList, ["1", "2", "3", "4", "5"])
      this.markerClusterer()
    }
  },
  methods: {
    //用于多选框的参数筛选
    screen(newVal, parmList, initList) {
      if (newVal.checked == true) {
        if (newVal.value == "total") {
          parmList = initList
        }
        else {
          parmList.push(newVal.value)
        }
      }
      if (newVal.checked == false) {
        if (newVal.value == "total") {
          parmList = []
        }
        else {
          parmList = parmList.filter(item => item !== newVal.value)
        }
      }
      return parmList;
    },
    initMap() {
      AMapLoader.load({
        key: "5ae01332406925a167770eda0fb8803d",// 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.MarkerClusterer'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          // viewMode:"3D",    //是否为3D地图模式
          zoom: 9,           //初始化地图级别
          center: [121.165666, 30.886314], //初始化地图中心点位置
        });
        // 构造矢量圆形
        this.markerClusterer()
      }).catch(e => {
        console.log(e);
      })

    },

    markerClusterer() {
      var placeTown = []
      if (this.districtList.includes('11')) {
        placeTown.push("11")
      }
      if (this.districtList.includes('22')) {
        if (this.townList.length == 0) {

          placeTown.push(...["1","2", "3", "4", "5", "6", "7", "8", "9", "10"])
        }
        else {
          placeTown.push(...this.townList)
        }
      }
      listMapAsset({
        address: this.address,
        placeTown,
        houseType: this.houseTypeList,
        floorSpaceStatus: this.floorSpaceList,
      }).then((res) => {
        if (res.data.code = "200") {
          var _that = this
          var markers = []
          var cluster
          res.data.data.map((data) => {
            var locationContent = ''
            var assetAttributes = ''
            locationContent = `<div><img style="width:40px;height:45px"  src="${require('@/assets/locationMap.png')}"></img></div>`
            assetAttributes = "自有资产"

            // 海量生成marker点
            var marker = new AMap.Marker({
              position: new AMap.LngLat(data.longitude, data.latitude),
              title: data.title,
              content: locationContent,
              offset: new AMap.Pixel(-15, -15),
            });



            var content = `<div style="width:100%;height:100%;display: flex; flex-direction: column;">
          <div style="width:100%;height:100%;display: flex; flex-direction: row;">
              <div style="font-weight:bold;font-family: Microsoft YaHei;font-size:17px;color: #484848;">${data.address}</div>
              <a style="font-weight:bold;font-family: Microsoft YaHei;font-size:12px;margin:4px;">
              </a>
          </div>
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 4px 0px 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">出租面积：</div>
              <div>${data.rentableSpace}㎡</div>
          </div>

          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">房屋属性：</div>
              <div>${data.houseDescribe}</div>
          </div>
          
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">周边公共交通：</div>
              <div >${data.publicTransportion}</div>
          </div>
          
          </div>

          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
                <img style="height: 70px;width:70px;margin-bottom: 2px; margin-right: 6px;" src="${data.housePlanformUrl[0]+'?x-oss-process=image/resize,l_90'}">
                <img style="height: 70px;width:70px;margin-bottom: 2px; margin-right: 6px;" src="${data.housePic[0]+'?x-oss-process=image/resize,l_90'}">
          </div>
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 8px 0px 0px 0px;justify-content: center;">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: red;">点击坐标点即可查看详情</div>
          </div>

          `;

            content.replace(/undefined/, "");

            // 窗体信息
            let infoWindow = new AMap.InfoWindow({
              content: content, //使用默认信息窗体框样式，显示信息内容
              offset: new AMap.Pixel(5, -10),
            });

            //监听marker的点击事件
            AMap.event.addListener(marker, "click", function (e) {
              _that.showDetail(data.id)

            });

            //监听marker的鼠标划入事件
            AMap.event.addListener(marker, "mouseover", function (e) {
              infoWindow.open(
                _that.map,
                // 窗口信息的位置
                marker.getPosition(data.lnt, data.lat)
              );
            });


            //添加监听事件，当前缩放级别
            AMap.event.addListener(_that.map, "zoomend", function () {
              // 关闭信息窗体
              _that.map.clearInfoWindow(infoWindow);
            });
            markers.push(marker);
          });

          this.markerList = markers
          //使用renderClusterMarker属性实现聚合点的完全自定义绘制
          var count = markers.length;
          var _renderClusterMarker = function (context) {
            var factor = Math.pow(context.count / count, 1 / 18);
            var div = document.createElement("div");
            var Hue = 180 - factor * 180;
            var bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
            var fontColor = "hsla(" + Hue + ",100%,20%,1)";
            var borderColor = "hsla(" + Hue + ",100%,40%,1)";
            var shadowColor = "hsla(" + Hue + ",100%,50%,1)";
            div.style.backgroundColor = bgColor;
            var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
            div.style.width = div.style.height = size + "px";
            div.style.border = "solid 1px " + borderColor;
            div.style.borderRadius = size / 2 + "px";
            div.style.boxShadow = "0 0 1px " + shadowColor;
            div.innerHTML = context.count;
            div.style.lineHeight = size + "px";
            div.style.color = fontColor;
            div.style.fontSize = "14px";
            div.style.textAlign = "center";
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
            context.marker.setContent(div);
          };

          if (this.cluster != null) {
            this.cluster.setMap(null);
          }

          // 点聚合样式
          cluster = new AMap.MarkerClusterer(this.map, markers, {
            gridSize: 45,
            minClusterSize: 25,
            renderClusterMarker: _renderClusterMarker
          });
          this.cluster = cluster

        }
      });
    },
    onSearch(){
      this.markerClusterer()
    },
    showDetail(id) {
      var assetId = id

            // 使用 this.$router.resolve 生成带参数的 URL
        let url = this.$router.resolve({
            name: 'houseDetail',
            query: { assetId }
        }).href;

        // 使用 window.open 在新标签页中打开 URL
        window.open(url, '_blank');
    }
  }

}
</script>

<style lang="less" scoped>
.ant-pro-components-tag-select {
  /deep/ .ant-pro-tag-select .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px;
  }
}

.list-articles-trigger {
  margin-left: 12px;
}
</style>

<style lang="less" scope>
.map {
  #container {
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
    width: 100%;
    height: 700px;
  }

  .amap-logo {
    display: none;
    opacity: 0 !important;
  }

  .amap-copyright {
    opacity: 0;
  }
}
</style>
